.App {
  text-align: center;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 100;
  color: #342b38;
}

.nav-box {
  background-color: white;
  margin: 0;
  padding: 20px;
  height: 30px;
  position: fixed;
  top: 0;
  right: 10px;
  width: 100%;
  z-index: 100;
}

.nav-box nav {
  display: flex;
  justify-content: flex-start;
  margin: 0 0 0 50px;
}

.nav-bar {
  list-style: none;
  display: none;
  justify-content: flex-end;
}

.nav-bar li {
  margin: 10px;
  color: #142850;
  font-size: 15px;
}

.nav-bar a {
  text-decoration: none;
  color: #342b38;
}

.menu-box {
  text-align: left;
}

.nav-bar-phone {
  list-style: none;
}

.nav-bar-phone li {
  margin: 20px 0;
  color: #142850;
  font-size: 35px;
}

.nav-bar-phone a {
  text-decoration: none;
  color: #342b38;
  padding: 10px;
  background-color: white;
}

.nav-box a:hover {
  color: #88e1f2;
}

#header {
  margin: 75px 0 0 0;
  padding: 0;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-family: "Playfair Display";
  font-size: 80px;
  color: #342b38;
  text-align: center;
  margin: 100px 0 15px;
}

h2 {
  font-family: "Playfair Display";
  font-weight: normal;
  font-size: 30px;
  color: #342b38;
  margin: 50px 0 20px;
  text-align: center;
}

h3 {
  font-family: "Roboto Condensed";
  font-weight: normal;
  font-size: 20px;
  background-color: #e4f9ff;
  transform: skew(9deg, 0deg);
  font-style: italic;

}

hr {
  width: 5%;
  border-style: none;
  border-bottom: 1px dashed;
  color: #342b38;
}


#intro {
  background: none;
  border: none;
  color: #f6cd61;
  font-size: 25px;
  margin: 10px;
  transform: skew(9deg, 0deg);
}

#intro:focus {
  outline: none;
}

#intro > span {
  text-decoration: none;
  font-family: "Roboto Condensed";
  font-style: italic;
  color: #342b38;
  background-color: #e8f044;
}

#intro > span:hover {
  background-color: #dbc6eb;
}

#about-me {
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#about-me > p {
  width: 50%;
  text-align: center;
  margin: 20px;
}

#jamesparmenter-pic {
  height: 300px;
  margin: 20px;
}

#services {
  height: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#services > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}

#services > div > article {
  text-align: center;
  margin: 20px;
  width: 80%;
}

.services-icon {
  width: 50px;
}

#clients {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#contact {
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#contact > p {
  text-align: center;
  width: 50%;
}

#contact > a {
  text-align: center;
  text-decoration: none;
}

.button-contact {
  border-radius: 40px;
  color: #342b38;
  font-family: "Roboto Condensed";
  font-size: 15px;
  font-weight: 500;
  padding: 10px;
  margin: 20px;
  background-color: #e4f9ff;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  width: 130px;
}

.button-contact:hover {
  background-color: #88e1f2;
}

.footer {
  height: 80px;
  font-size: 10px;
  text-align: center;
  margin: 0;
  padding: 25px 0 0 0;
}

.footer > p > a {
  text-decoration: none;
  color: #342b38;
}

.picbox {
  display: flex;
  justify-content: space-evenly;
  padding-top: 50px;
}

.logopic {
  height: 30px;
  width: 60px;
  object-fit: contain;
}

.menupic {
  width: 40px;
}


@media (min-width: 600px) {
  #services {
    height: 500px;
  }

  #services > div {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    margin: 50px 0;
  }

  .logopic {
    height: 50px;
    width: 100px;
    object-fit: contain;
  }

  .nav-box nav {
    justify-content: flex-end;
  }

  .nav-box ul {
    display: flex;
  }

  .menupic {
    display: none;
  }

  #services > div > article {
    width: 30%;
  }
}

@media (min-width: 1000px) {
  #services {
    height: 550px;
  }

  #services > div {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    margin: 50px 0;
  }
}

